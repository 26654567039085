import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

export default function InvoicesOverview() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/invoices`,
        );
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices', error);
      }
    };

    fetchInvoices();
  }, []);

  // Function to handle invoice download
  const handleDownload = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}/pdf`,
        { responseType: 'blob' }, // Important: receive file as a blob
      );

      // Create a blob URL and initiate the download
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${invoiceId}.pdf`; // Set the file name
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Clean up the URL
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
      filters: invoices.map((invoice) => ({
        text: invoice.invoiceNumber,
        value: invoice.invoiceNumber,
      })),
      onFilter: (value, record) => record.invoiceNumber.includes(value),
    },
    {
      title: 'Contact Name',
      dataIndex: ['contact', 'name'],
      key: 'contact.name',
      sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text) => `${text.toFixed(2)} CHF`,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
    },
    {
      title: 'Invoice Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span>
          {status === 'Paid' ? (
            <Tag icon={<CheckCircleOutlined />} color="green">
              Paid
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="red">
              Unpaid
            </Tag>
          )}
        </span>
      ),
      filters: [
        { text: 'Paid', value: 'Paid' },
        { text: 'Unpaid', value: 'Unpaid' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Link to={`/invoices/edit/${record._id}`}>
            <Button type="primary" style={{ marginRight: '8px' }}>
              Edit
            </Button>
          </Link>
          <Button type="default" onClick={() => handleDownload(record._id)}>
            Download
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ alignContent: 'left', padding: '20px' }}>
      <h1>Invoices Overview</h1>
      <Table
        columns={columns}
        dataSource={invoices}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}
