import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';

function TransactionList() {
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState({});

  useEffect(() => {
    async function fetchTransactions() {
      try {
        const transactionResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/transactions`,
        );
        const transactions = transactionResponse.data;

        // Extract unique account IDs from transactions
        const accountIds = [
          ...new Set(
            transactions.flatMap((t) => [t.debitAccount, t.creditAccount]),
          ),
        ];

        // Fetch account details for all unique account IDs
        const accountResponses = await Promise.all(
          accountIds.map((id) =>
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/accounts/${id}`),
          ),
        );

        // Create a map of account ID to account details
        const accountsMap = accountResponses.reduce((acc, response) => {
          const account = response.data;
          acc[account._id] = account; // Assuming the account ID is `_id`
          return acc;
        }, {});

        setAccounts(accountsMap);

        // Set transactions data
        setTransactions(transactions);
      } catch (error) {
        console.error('Error fetching transactions or accounts', error);
      }
    }
    fetchTransactions();
  }, []);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Debit Account',
      dataIndex: 'debitAccount',
      key: 'debit',
      render: (accountId) => {
        const account = accounts[accountId];
        return account
          ? `${account.accountName} (${account.accountNumber})`
          : accountId;
      },
    },
    {
      title: 'Credit Account',
      dataIndex: 'creditAccount',
      key: 'credit',
      render: (accountId) => {
        const account = accounts[accountId];
        return account
          ? `${account.accountName} (${account.accountNumber})`
          : accountId;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Link to={`/transactions/edit/${record._id}`}>
          <Button icon={<EditOutlined />} type="primary">
            Edit
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div style={{ alignContent: 'left', padding: '20px' }}>
      <h1>Transactions</h1>
      <Table
        columns={columns}
        dataSource={transactions}
        rowKey="_id"
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
}

export default TransactionList;
