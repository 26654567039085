import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Layout, Menu, Drawer } from 'antd';
import {
  BookOutlined,
  ContactsOutlined,
  WalletOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import CreateInvoice from './Invoices/CreateInvoice';
import EditInvoice from './Invoices/EditInvoice';
import Contacts from './Contacts/Contacts';
import CreateContacts from './Contacts/CreateContacts';
import InvoicesOverview from './Invoices/InvoicesOverview';
import TransactionList from './Transactions/TransactionList';
import EditTransaction from './Transactions/EditTransaction';
import CreateTransaction from './Transactions/CreateTransaction';
import CreatePayment from './Payment/CreatePayment';
import Overview from './Accounting/Overview';
import Setting from './Settings/Settings';
import CamtChecker from './Payment/CamtChecker';

const { Header, Sider, Content } = Layout;

const menuItems = [
  {
    key: 'contacts',
    label: 'Contacts',
    icon: <ContactsOutlined />,
    children: [
      { key: 'contacts/overview', label: 'Contacts Overview' },
      { key: 'contacts/create', label: 'Create Contacts' },
    ],
  },
  {
    key: 'invoices',
    label: 'Invoices',
    icon: <WalletOutlined />,
    children: [
      { key: 'invoices/overview', label: 'Invoices Overview' },
      { key: 'invoices/create', label: 'Create Invoice' },
    ],
  },
  {
    key: 'transactions',
    label: 'Transactions',
    icon: <BookOutlined />,
    children: [
      { key: 'transactions/overview', label: 'Manage Transactions' },
      { key: 'transactions/create', label: 'Create Transaction' },
    ],
  },
  {
    key: 'payments',
    label: 'Payments',
    icon: <BookOutlined />,
    children: [
      { key: 'payments/create', label: 'Create Payment' },
      { key: 'payments/receive', label: 'Receive Payments' },
    ],
  },

  {
    key: 'accounting',
    label: 'Accounting',
    icon: <BookOutlined />,
    children: [
      { key: 'accounting/overview', label: 'Balance & Income Statement' },
    ],
  },
];

const KeycloakContext = createContext();

export const useKeycloak = () => useContext(KeycloakContext);

// TopBar Component for search and settings
const TopBar = () => {
  const navigate = useNavigate();

  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    keycloak.logout();
  };

  const handleSettings = () => {
    navigate('settings');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* Placeholder for additional elements (if needed) */}
      <div />

      {/* Settings and Logout Icons */}
      <div style={{ display: 'flex', gap: '16px' }}>
        <SettingOutlined
          style={{
            fontSize: '18px',
            cursor: 'pointer',
            color: '#fff',
          }}
          onClick={handleSettings}
        />
        <LogoutOutlined
          style={{
            fontSize: '18px',
            cursor: 'pointer',
            color: '#fff',
          }}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

// Main App Content with Sidebar and Routing
const AppContent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState([]); // Manage open submenu keys
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname.substring(1));

  useEffect(() => {
    setCurrent(location.pathname.substring(1));
    const pathSegments = location.pathname.split('/').filter(Boolean);
    if (pathSegments.length > 1) {
      setOpenKeys([pathSegments[0]]); // Automatically expand the relevant submenu
    }
  }, [location]);

  const handleMenuClick = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
    setDrawerVisible(false);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys); // Update openKeys state when submenu is toggled
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Top Bar */}
      <Header className="ant-layout-header ant-layout-header-shadow">
        <TopBar toggleDrawer={toggleDrawer} />
      </Header>

      {/* Main Layout with Sidebar and Content */}
      <Layout>
        <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          style={{ padding: 0 }}
        >
          <Menu
            mode="inline"
            selectedKeys={[current]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange} // Handle submenu toggles
            onClick={handleMenuClick}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Drawer>

        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed) => setCollapsed(collapsed)}
          style={{ display: drawerVisible ? 'none' : 'block' }}
          className="site-layout-background"
        >
          <Menu
            mode="inline"
            selectedKeys={[current]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            onClick={handleMenuClick}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
          />
        </Sider>

        {/* Content Area */}
        <Layout style={{ padding: '0 2px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: '#fff',
            }}
          >
            <Routes>
              <Route path="/contacts/overview" element={<Contacts />} />
              <Route path="/contacts/create" element={<CreateContacts />} />
              <Route path="/invoices/create" element={<CreateInvoice />} />
              <Route path="/invoices/overview" element={<InvoicesOverview />} />
              <Route path="/invoices/edit/:id" element={<EditInvoice />} />
              <Route
                path="/transactions/overview"
                element={<TransactionList />}
              />
              <Route
                path="/transactions/create"
                element={<CreateTransaction />}
              />
              <Route
                path="/transactions/edit/:transactionId"
                element={<EditTransaction />}
              />
              <Route path="/payments/create" element={<CreatePayment />} />
              <Route path="/payments/receive" element={<CamtChecker />} />
              <Route path="/accounting/overview" element={<Overview />} />
              <Route path="/settings" element={<Setting />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(null);
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);
  const keycloakInstance = useRef(null);

  useEffect(() => {
    if (!keycloakInstance.current) {
      keycloakInstance.current = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
      });

      keycloakInstance.current
        .init({ onLoad: 'login-required' })
        .then((auth) => {
          setAuthenticated(auth);
          setKeycloakInitialized(true);
          if (!auth) {
            keycloakInstance.current.login();
          }

          axios.defaults.headers.common['Authorization'] =
            `Bearer ${keycloakInstance.current.token}`;
        })
        .catch((err) => {
          console.error('Failed to initialize Keycloak', err);
          setAuthenticated(false);
          setKeycloakInitialized(true);
        });
    }
  }, []);

  if (!keycloakInitialized) {
    return <div>Loading...</div>;
  }

  if (authenticated === false) {
    return <div>Not authenticated. Redirecting to login...</div>;
  }

  return (
    <KeycloakContext.Provider
      value={{ keycloak: keycloakInstance.current, authenticated }}
    >
      <Router>
        <AppContent />
      </Router>
    </KeycloakContext.Provider>
  );
};

export default App;
