import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, Button, notification } from 'antd';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const { Option } = Select;

export default function EditInvoice() {
  const { id: invoiceId } = useParams();
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [items, setItems] = useState([
    { description: '', quantity: 1, price: 0 },
  ]);
  const [dueDate, setDueDate] = useState(dayjs());
  const [invoiceDate, setInvoiceDate] = useState(dayjs());

  useEffect(() => {
    // Fetch contacts list
    const fetchContacts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/contacts`,
        );
        setContacts(response.data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    // Fetch invoice data
    const fetchInvoice = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`,
        );
        const invoice = response.data.invoice;

        setSelectedContact(invoice.contact._id);
        setItems(invoice.items);
        setDueDate(dayjs(invoice.dueDate));
        setInvoiceDate(dayjs(invoice.invoiceDate));
      } catch (error) {
        console.error('Error fetching invoice:', error);
      }
    };

    fetchContacts();
    fetchInvoice();
  }, [invoiceId]);

  const handleContactChange = (value) => {
    setSelectedContact(value);
  };

  const handleAddItem = () => {
    setItems([...items, { description: '', quantity: 1, price: 0 }]);
  };

  const handleItemChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };

  const handleSubmit = async () => {
    const totalAmount = items.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0,
    );
    const invoice = {
      contact: contacts.find((contact) => contact._id === selectedContact),
      items,
      totalAmount,
      dueDate: dueDate.format('DD-MM-YYYY'),
      invoiceDate: invoiceDate.format('DD-MM-YYYY'),
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/invoices/${invoiceId}`,
        invoice,
      );

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Invoice updated successfully',
        });
        navigate('/invoices'); // Use navigate to redirect to the invoice list
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update invoice',
        });
      }
    } catch (error) {
      console.error('Error updating invoice:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to update invoice',
      });
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <h1>Edit Invoice</h1>
      <Form.Item label="Select Contact" required>
        <Select
          value={selectedContact}
          onChange={handleContactChange}
          placeholder="Select a contact"
        >
          {contacts.map((contact) => (
            <Option key={contact._id} value={contact._id}>
              {contact.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Due Date" required>
        <DatePicker
          value={dueDate}
          onChange={setDueDate}
          format="DD-MM-YYYY"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item label="Invoice Date" required>
        <DatePicker
          value={invoiceDate}
          onChange={setInvoiceDate}
          format="DD-MM-YYYY"
          style={{ width: '100%' }}
        />
      </Form.Item>

      {items.map((item, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <Form.Item label="Description" required>
            <Input
              placeholder="Description"
              value={item.description}
              onChange={(e) =>
                handleItemChange(index, 'description', e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Quantity" required>
            <Input
              type="number"
              min="0"
              step="0.01"
              value={item.quantity}
              onChange={(e) =>
                handleItemChange(index, 'quantity', e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Price" required>
            <Input
              type="number"
              min="0"
              step="0.01"
              value={item.price}
              onChange={(e) => handleItemChange(index, 'price', e.target.value)}
            />
          </Form.Item>
        </div>
      ))}
      <Button
        type="dashed"
        onClick={handleAddItem}
        style={{ marginBottom: '20px' }}
      >
        Add Item
      </Button>

      <Button type="primary" htmlType="submit">
        Update Invoice
      </Button>
    </Form>
  );
}
