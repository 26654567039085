import React, { useState } from 'react';
import { Upload, Button, Table, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

export default function CamtChecker() {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const uploadProps = {
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    fileList: file ? [file] : [],
  };

  const handleCheckPayments = async () => {
    if (!file) {
      notification.error({
        message: 'Error',
        description: 'Please upload a CAMT file.',
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/receive-payments`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      setResult(response.data);
      notification.success({
        message: 'Success',
        description: 'CAMT file processed successfully.',
      });
    } catch (error) {
      console.error('Error processing file:', error);
      notification.error({
        message: 'Error',
        description: 'An error occurred while processing the CAMT file.',
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Receive Payments</h1>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Upload CAMT File</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleCheckPayments}
        style={{ marginTop: '20px' }}
        loading={loading}
      >
        Check Payments
      </Button>
      <Table
        dataSource={result}
        columns={columns}
        rowKey="invoiceNumber"
        style={{ marginTop: '20px' }}
      />
    </div>
  );
}
