import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd';
import axios from 'axios';

const SettingsForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // Fetch settings from API
  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/settings`
      );

      if (response.data && response.data.length > 0) {
        const settingsData = response.data[0];
        form.setFieldsValue({
          iban: settingsData.iban,
          accountName: settingsData.accountName,
          bic: settingsData.bic,
          invoiceDueDays: settingsData.invoiceDueDays,
          address: settingsData.address,
          buildingNumber: settingsData.buildingNumber,
          zip: settingsData.zip,
          city: settingsData.city,
          country: settingsData.country,
        });
      }
    } catch (err) {
      console.error('Error fetching settings:', err);
      notification.error({ message: 'Failed to load settings' });
    } finally {
      setLoading(false);
    }
  };

  // Save settings
  const handleSave = async () => {
    try {
      setSaving(true);
      const updatedSettings = form.getFieldsValue();

      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/settings/6756002784e4f71901276b13`,
        updatedSettings
      );

      notification.success({ message: 'Settings updated successfully' });
      fetchSettings(); // Refresh after save
    } catch (err) {
      console.error('Error saving settings:', err);
      notification.error({ message: 'Failed to save settings' });
    } finally {
      setSaving(false);
    }
  };

  // Load settings on component mount
  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div style={{ padding: '20px', maxWidth: '800px' }}>
      <h1>Settings</h1>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSave}
        style={{ maxWidth: '800px', marginBottom: '20px' }}
      >
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[{ required: true, message: 'Please input IBAN!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Account Name"
          name="accountName"
          rules={[{ required: true, message: 'Please input Account Name!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="BIC"
          name="bic"
          rules={[{ required: true, message: 'Please input BIC!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Invoice Due Days"
          name="invoiceDueDays"
          rules={[
            { required: true, message: 'Please input Invoice Due Days!' },
          ]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please input Address!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Building Number"
          name="buildingNumber"
          rules={[
            { required: true, message: 'Please input Building Number!' },
          ]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="ZIP"
          name="zip"
          rules={[{ required: true, message: 'Please input ZIP!' }]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input City!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input Country!' }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={saving}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SettingsForm;
